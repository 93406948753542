import React from 'react';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const StyledFooter = styled.footer`
  margin-top: 5rem;
  padding: 15px 0 2rem 0;
`;

const Container = styled.footer`
  display: flex;
  column-gap: 45px;
  margin: 20px auto 0 auto;
  border-top: 2px solid #f56835;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  font-family: Helvetica;

  @media (min-width: 768px) {
    max-width: 768px;
  }
`;

const FooterHeading = styled.h5`
  margin-bottom: 1rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const List = styled.ul`
  li {
    margin-bottom: 0.3rem;
  }
`;

const StyledOutboundLink = styled(OutboundLink)`
  display: block;
  margin-bottom: 0.6rem;
  text-decoration: none;
  color: #000;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledInternalLink = styled.a`
  display: block;
  margin-bottom: 0.6rem;
  text-decoration: none;
  color: #000;

  &:hover {
    text-decoration: underline;
  }
`;

type InternalLink = {
  path: string;
  name: string;
};

type SocialLink = {
  href: string;
  name: string;
};

const socialLinks: SocialLink[] = [
  { href: 'https://twitter.com/sfrostwick', name: 'Twitter' },
  {
    href: 'https://www.linkedin.com/in/stevenfrostwick/',
    name: 'LinkedIn',
  },
];

const internalLinks: InternalLink[] = [
  { path: '/today-i-learned', name: 'Today I Learned' },
  { path: '/projects', name: 'Projects' },
  { path: '/contact', name: 'Contact' },
];

export default function Footer() {
  return (
    <StyledFooter>
      <Container>
        <section>
          <FooterHeading>Site</FooterHeading>
          <List>
            {internalLinks.map((internalLink) => (
              <li key={internalLink.name}>
                <StyledInternalLink href={internalLink.path}>
                  {internalLink.name}
                </StyledInternalLink>
              </li>
            ))}
          </List>
        </section>

        <section>
          <FooterHeading>Social</FooterHeading>
          <List>
            {socialLinks.map((socialLink) => (
              <li key={socialLink.name}>
                <StyledOutboundLink href={socialLink.href}>
                  {socialLink.name}
                </StyledOutboundLink>
              </li>
            ))}
          </List>
        </section>
      </Container>
    </StyledFooter>
  );
}
