import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled, { createGlobalStyle } from 'styled-components';

import Header from './Header';
import Footer from './Footer';

import mainImage from '../images/steven-frostwick-on-a-train.jpg';

// CSS Reset
const GlobalStyle = createGlobalStyle`
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  overflow-y: scroll;
}

p {
  margin-block-start: 1em;
  margin-block-end: 1em;
}
`;

const Container = styled.div`
  margin: 20px auto;
  padding: 0 15px;
  width: 100%;
  box-sizing: border-box;
  font-family: Helvetica;

  @media (min-width: 768px) {
    max-width: 768px;
  }
`;

const Titles = styled.div`
  margin: 20px 0;

  @media (min-width: 576px) {
    margin: 40px 0;
  }
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: 700;

  @media (min-width: 576px) {
    font-size: 48px;
  }
`;

const SubTitle = styled.h3`
  margin-top: 0.7rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #475569;
`;

type LayoutProps = {
  children: React.ReactNode;
  optionalTitleChildren?: React.ReactNode;
  subTitle?: string;
  title: string;
};

export default function Layout(props: LayoutProps) {
  const { children, optionalTitleChildren, subTitle, title } = props;

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Steven Frostwick</title>
        <meta property="og:title" content="Steven Frostwick" />
        <meta
          property="og:description"
          content="Steven Frostwick is a software developer based in Vancouver."
        />
        <meta property="og:image" content={mainImage} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <GlobalStyle />
      <Container>
        <Header />
        <Titles>
          <Title>{title}</Title>
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
          {optionalTitleChildren && optionalTitleChildren}
        </Titles>
        {children}
      </Container>
      <Footer />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
