import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const links = [
  { to: '/projects', text: 'Projects' },
  { to: '/today-i-learned', text: 'Today I Learned' },
  {
    to: '/contact',
    text: 'Contact',
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  font-weight: bold;

  @media (min-width: 576px) {
    flex-direction: row;
    margin-bottom: 80px;
  }
`;

const Heading = styled.h1`
  margin-bottom: 5px;
  font-size: 24px;

  @media (min-width: 576px) {
    margin: 0;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
`;

const ListItem = styled.li`
  margin: 10px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;

  &:last-of-type {
    @media (min-width: 576px) {
      margin-right: 0;
    }
  }
`;

const StyledLink = styled(Link)`
  margin-bottom: 15px;
  border-bottom: 4px solid transparent;
  padding: 10px;
  text-decoration: none;
  color: black;

  &:hover {
    border-bottom: 4px solid #f3683e;
  }

  &:first-of-type {
    padding: 0;
  }

  @media (min-width: 576px) {
    margin: 0;
  }
`;

export default function Header() {
  return (
    <Container>
      <StyledLink to="/" activeStyle={{ borderBottom: '4px solid #f3683e' }}>
        <Heading>Steven Frostwick</Heading>
      </StyledLink>
      <nav>
        <List>
          {links.map((link) => {
            return (
              <ListItem key={link.to}>
                <StyledLink
                  to={link.to}
                  title={link.text}
                  activeStyle={{ borderBottom: '4px solid #f3683e' }}
                  partiallyActive={true}
                >
                  {link.text}
                </StyledLink>
              </ListItem>
            );
          })}
        </List>
      </nav>
    </Container>
  );
}
